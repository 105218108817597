<template>
  <div>
    <component :is="componentName"></component>
  </div>
</template>

<script>
import workOrderDetail from "./detail";
import workOrderList from "./list"
export default {
  name: "work-order-management",
  components:{
    workOrderDetail,
    workOrderList
  },
  watch:{
    '$route.path':function (newval,oldVal) {
        if(newval.includes('/detail')){
          this.componentName = 'workOrderDetail'
          return;
        }
        if(newval.includes('/propertyModule/property-repair/workorder-management')){
          this.componentName = 'workOrderList'

        }
    }
  },
  data(){
    return {
      componentName:'workOrderList'
    }
  }
}
</script>

<style scoped>

</style>