
<template>
  <div class="repair-item-wrapper flex">
    <div class="search-content searchBox">
      <div class="search-item">
        <label>工单号</label>
        <el-input type="text" v-model="filter.orderNo" placeholder="请输入" size="medium" clearable></el-input>
      </div>
      <div class="search-item">
        <label>状态</label>
        <el-select v-model="filter.statusList" size="medium" placeholder="请选择" clearable>
          <el-option v-for="(st,index) in statusMap.keys()" :label="statusMap.get(st)[1]" :key="index" :value="st"></el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label>报修日期</label>
        <el-date-picker class="range-input"
                        :default-time="['00:00:00','23:59:59']"
                        size="medium"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        is-range
                        v-model="time"
                        range-seperator="至"
                        start-placeholder="开始时间"
                        @change="timeChange"
                        end-placeholder="结束时间"></el-date-picker>
      </div>
      <div class="search-item button-group pdl16">
        <el-button type="primary" class="btn-search" @click="search()" size="medium">查询</el-button>
        <el-button  class="btn-reset" @click="time=[];resetSearch()" size="medium">重置</el-button>
      </div>
    </div>
    <div class="table-content inline-flex contentBox">
      <el-table :data="tableData" class="contentTable" size="medium" row-key="id" :tree-props="{children:'child',hasChildren:'hasChildren'}">
        <el-table-column prop="orderNo" label="工单号"></el-table-column>
        <el-table-column prop="address" label="报修区域">
          <template slot-scope="{row}">
            {{row.repairArea && row.repairArea.areaName}}
          </template>
        </el-table-column>
        <el-table-column label="报修人员">
          <template slot-scope="{row}">
            {{row.createBySysUserName && row.createBySysUserName.username}}
          </template>
        </el-table-column>
        <el-table-column prop="repairUserName" label="维修人员"></el-table-column>
        <el-table-column prop="typeName" label="故障类型">
          <template slot-scope="{row}">
            {{row.repairType && row.repairType.typeName}}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="报修时间"></el-table-column>
        <el-table-column prop="finishTime" label="工单完成时间"></el-table-column>
        <el-table-column prop="statusName" label="状态">
          <template slot-scope="{row}">
             <span class="property-status" :class="[getStatus(row.status,'className')]" >
              {{row.statusName}}
             </span>
          </template>
        </el-table-column>
        <el-table-column  label="操作">
          <template  slot-scope="{row}">
            <span class="mgr10 blue" v-if="row.status===3" @click="dispatch(row)">派单</span>
            <span class="mgr10 blue" @click="showDetail(row.id)">查看</span>
          </template>
        </el-table-column>
      </el-table>
      <reconova-pagination @paginationChange="paginationChange" :page-no="pageIndex" :page-size="pageSize" :total="total"></reconova-pagination>
    </div>
    <div class="dialog-wrapper">
      <dispatch-order v-if="showEditModal" :show.sync="showEditModal" :id="editData.id" :type-id="editData.repairType.id" :user-id="editData.createBySysUserName.id"></dispatch-order>
    </div>
  </div>
</template>

<script>
import ReconovaPagination from "@/components/reconova/reconovaPagination";
import pagination from "@/mixins/pagination";
import DispatchOrder from "./dispatch-order";
export default {
  name: "work-order-list",
  components: {
    DispatchOrder,
    ReconovaPagination
  },
  mixins:[pagination],
  data(){
    return {
      time:[],
      filter:{
      },
      editData:{},
      tableData:[],
      showEditModal:false,
      maintainedStatus:"9,10,11,12",
      toRepairStatus:"5,6",
      statusMap:null
    }
  },
  created(){
    this.statusMap = new Map([
      [3,['to-be-assigned','待分配']],
      [4,['to-be-accepted','待接单']],
      [this.toRepairStatus,['to-be-repaired','待维修']],
      [8,['in-maintain','维修中']],
      [this.maintainedStatus,['maintained','已维修']],
      [13,['done','已完成']],
      [7,["terminated",'已终止']],
      [14,['hang-on','挂起']]
    ])
    this.search()
  },
  methods:{

    // 查询
    search(pageSize=10,pageIndex=1){
      let requestParam = this.getParams(pageSize,pageIndex);
      if(requestParam.statusList&&requestParam.statusList==14){
        requestParam.statusList=3;
        requestParam.isHangUp=true;
      }else if(requestParam.statusList&&requestParam.statusList==3){
        requestParam.isHangUp=false;
      }
      this.$api.property.getWorkOrderList(requestParam).then(response=>{
        this.tableData = response.data.list;
        this.total = response.data.total;
        this.pageIndex = response.data.pageNum;
        this.pageSize = response.data.pageSize;
      })
    },

    /**
     * @param status  -- 状态
     * @param prop  -- className/statusName
     */
    getStatus(status,prop){
      if(this.maintainedStatus.includes(status)){
        return prop==='className'?this.statusMap.get(this.maintainedStatus)[0]:this.statusMap.get(this.maintainedStatus)[1]
      }else if(this.toRepairStatus.includes(status)){
        return prop==='className'?this.statusMap.get(this.toRepairStatus)[0]:this.statusMap.get(this.toRepairStatus)[1]
      }else{
        return prop==='className'?this.statusMap.get(status)[0]:this.statusMap.get(status)[1]
      }
    },


    dispatch(rowData){
      this.editData = JSON.parse(JSON.stringify(rowData));
      this.showEditModal = true;
    },

    showDetail(id){
      this.$router.push(`/propertyModule/property-repair/workorder-management/detail/${id}`)
    },

    timeChange(value){
      this.filter.submitStartTime = value?value[0]:'';
      this.filter.submitEndTime = value?value[1]:'';
    }
  }
}
</script>

<style scoped lang="scss">
.property-status.to-be-assigned{
  background: #c07ffe;
}
.property-status.to-be-accepted{
  background: $evaluateColor;
}
.property-status.to-be-repaired{
  background: $handlingColor;
}
.property-status.in-maintain{
  background: #FF8D19;
}
.property-status.maintained{
  background: #01BFBB;
}
.property-status.done{
  background: $completeColor;
}
.property-status.terminated{
  background:#DA001B;
}
.property-status.hang-on{
  background: #d7001c;
}
.el-input,.el-select {
  width: 220px;
}
.range-input{
  width: 400px;
}
.el-table{
  flex: 1;
  overflow-y: auto;
}
</style>