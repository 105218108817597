<template>
  <el-dialog title="工单分派" width="800px" :visible.sync="_show">
    <div class="main">
      <order-info :info="detail"></order-info>
      <div class="dispatch-block">
        <div class="clearfix">
          <span class="dispatch-label">分配维修工</span>
          <el-input
              class="filter-input"
              size="medium"
              placeholder="输入姓名，按回车键搜索"
              @change="getWorker"
              v-model="filterName">
          </el-input>
        </div>
        <div class="worker-list">
          <div v-for="(per,index) in workerList" :key="index" class="worker-item">
            <div class="detail-block">
              <span class="inline-block worker-item_name">{{per.name}}<span :class="['worker-item_status',per.processOrderCount==0?'green':'deepblue']">{{per.processOrderCount>0?'接单中':'待接单'}}</span></span>
              <span class="inline-block">已接工单：{{per.processOrderCount}}</span>
              <span class="inline-block">工人评价：{{per.totalEvaluationScore}}分</span>
            </div>
            <div class="detail-block">
              <span class="inline-block">电话：{{per.mobile}}</span>
              <div class="inline-block type-list" v-if="!per.showMore">
                <span class="worker-item_type height32" ref="workType">工种：{{per.hasAllType?'全能':per.worktype}}</span>
              </div>
              <div class="inline-block type-list" v-else>
                <span>工种：</span>
                <div>
                  <div v-for="ite in per.types">{{ite.typeName}}</div>
                </div>
              </div>
              <span class="blue" @click="per.showMore=!per.showMore" v-if="per.worktype.length>18">{{per.showMore?'收起':'更多'}}</span>
            </div>
            <el-radio class="worker-item_select" v-model="detail.workerId" :label="per.id">{{''}}</el-radio>
          </div>
          <div class="no-data" v-if="workerList.length==0">暂无数据</div>
        </div>
      </div>
    </div>
    <span slot="footer">
      <el-button size="medium" @click="_show=false">取消</el-button>
      <el-button size="medium" type="primary" @click="submitEdit" :loading="submitLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import OrderInfo from "../../components/order-info";
export default {
  name: "dispatch-order",
  components: {OrderInfo},
  props:{
    show:{
      type:Boolean,
      default:false
    },
    id:{
      type:Number,
      default: 0
    },
    userId:{
      type:Number,
      default: 0
    },
    typeId:{
      type:Number,
      default: 0
    }
  },
  data(){
    return {
      detail:{

      },
      submitLoading:false,
      workerList:[],
      filterName:''
    }
  },
  computed:{
    _show:{
      get(){
        return this.show;
      },
      set(val){
        this.$emit("update:show",val)
      }
    }
  },
  mounted(){
    this.getDetail(this.id)
    this.getWorker()
  },
  methods:{
    getDetail(id){
      this.$api.property.getWorkOrderDetail({id}).then(response=>{
        this.detail = Object.assign({},response.data,this.detail)
        this.getRepairerInfo(this.detail.createBy);
      })
    },
    getWorker(){
      this.$api.property.dispatchWorker({typeId:this.typeId,pageNum:1,pageSize:5000,name:this.filterName}).then(response=>{
        this.workerList = response.data.list;
        this.$set(this.detail,'workerId',this.workerList[0].id)
        this.workerList.forEach(itr=>{
          this.$set(itr,'showMore',false)
          this.$set(itr,'worktype',itr.types?itr.types.map(e=>e.typeName).join(','):'')
          return itr
        })
      })
    },

    // 根据报修人编号查询报修人的详细信息
    getRepairerInfo(id){
      this.$api.property.getShopByUserId({id}).then(res=>{
        this.detail = Object.assign({},{
          idCard:res.data.idCard,
          mobile:res.data.mobile,
          shopName:res.data.shopName,
          shopNo:res.data.shopNo,
          username:res.data.username,
        },this.detail)
      })
    },

    submitEdit(){
      this.submitLoading = true;
      this.$api.property.dispatchOrder({id:this.id,userId:this.detail.workerId}).then(res=>{
        this.submitLoading = false;
        if(res.code===200){
          this.$message.success('操作成功')
          this._show=false;
          this.$parent.search();
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-radio__inner{
  width: 18px;
  height: 18px;
}
::v-deep .el-radio__inner::after{
  width: 6px;
  height: 6px;
}
.main{
  .dispatch-block{
    >label{
      width: 90px;
      text-align: right;
    }
    .dispatch-label{
      height: 34px;
      line-height: 34px;
      display: inline-block;
      padding-left: 35px;
      font-size: 15px;
      font-weight: bold;
    }
    .filter-input{
      width: 260px;
      float: right;
      margin-right: 30px;
    }
    .worker-list{
      max-height: 386px;
      overflow-y: auto;
      padding: 0 30px;
      .worker-item{
        border: 1px solid #e3e3e3;
        border-radius: 8px;
        padding: 10px;
        position: relative;
        .detail-block{
          line-height: 32px;
          box-sizing: border-box;
          .inline-block{
            width: 30%;
          }
        }
        .type-list{
          display: inline-flex;
          vertical-align: top;
          >span{
            white-space: nowrap;
          }
        }
        .worker-item_select{
          position: absolute;
          right: 20px;
          top:30px;
        }

        .worker-item_name{
          font-size: 16px;
          font-weight: bold;
          .worker-item_status{
            vertical-align: middle;
            font-weight: normal;
            display: inline-block;
            padding: 0 10px;
            height: 22px;
            line-height: 22px;
            background: #d6d3d3;
            border-radius: 12px;
            margin-left: 12px;
            font-size: 12px;
            color: #fff;
            &.green{
              background: $evaluateColor;
            }
            &.deepblue{
              background: #0331B7;
            }
          }

        }
      }
      .no-data{
        padding: 10px 5px;
        text-align: center;
      }
    }
  }
}
.worker-item+.worker-item{
  margin-top: 12px;
}
.worker-item_type{
  line-height: 1.5;
  vertical-align: top;
  margin-top: 5px;
  display: inline-block;
  &.height32{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

  }
  &.heightAuto{
    height: auto;
  }

}

</style>